// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import * as Constants from '../../../../constants'
import { getUserData, getHearderAuth } from '@utils'
// ** Axios Imports
import axios from 'axios'

export const getUserProfile = createAsyncThunk('appChat/getTasks', async () => {
  const userData = getUserData()
  const profileUser = {
    id: userData.id,
    avatar: userData.avatar,
    fullName: userData.email,
    role: userData.fullName,
    about: '',
    status: 'online',
    settings: {
      isTwoStepAuthVerificationEnabled: true,
      isNotificationsOn: false
    }
  }
  return profileUser

  // const response = await axios.get('/apps/chat/users/profile-user')
  // return response.data
})

export const reloadDataChats = createAsyncThunk('appChat/reloadDataChats', async () => {
  let list_chats = []
  let list_contact = []
  if (sessionStorage.getItem("contact_data") && sessionStorage.getItem("chat_data")) {
    list_contact = JSON.parse(sessionStorage.getItem("contact_data"))
    list_chats = JSON.parse(sessionStorage.getItem("chat_data"))
  }
  const userData = getUserData()
  const profileUserData = {
    id: userData.id,
    avatar: userData.avatar,
    fullName: userData.fullName,
    status: 'online'
  }

  const datachatsContacts = list_chats.map(chat => {
    const contact = list_contact.find(c => c.id === chat.userId)
    contact.chat = { id: chat.id, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
    return contact
  })
  return { chatsContacts: datachatsContacts, contacts: list_contact, profileUser: profileUserData }
})

export const getChatContacts = createAsyncThunk('appChat/getChatContacts', async (vl, { dispatch }) => {
  const list_chats = []
  const list_contact = []
  const config = {
    method: 'get',
    url: `${Constants.REST_API_WORKSUITE}list-user-chat`,
    headers: getHearderAuth()
  }

  // if (sessionStorage.getItem("contact_data") && sessionStorage.getItem("chat_data")) {
  //   list_contact = JSON.parse(sessionStorage.getItem("contact_data"))
  //   list_chats = JSON.parse(sessionStorage.getItem("chat_data"))
  // } else {
  await axios(config)
    .then(function (response) {
      if (response.data.length > 0) {
        response.data.forEach(async (user) => {
          const temp_u = {
            id: user.id,
            fullName: user.name,
            role: user.user_other_role,
            about: '',
            avatar: user.image_url,
            status: 'away'
          }
          list_contact.push(temp_u)
          sessionStorage.setItem("contact_data", JSON.stringify(list_contact))

          const config2 = {
            method: 'get',
            url: `${Constants.REST_API_WORKSUITE}chat-message/${user.id}`,
            headers: getHearderAuth()
          }
          // if (sessionStorage.getItem("chat_data")) {
          //   list_chats = JSON.parse(sessionStorage.getItem("chat_data"))
          // } else {
          await axios(config2)
            .then(function (response2) {
              const res_chat = response2.data
              const arr_chat = res_chat.data
              if (arr_chat.length > 0) {
                let dem = 0
                const id_user = parseInt(res_chat.idu)
                const temp_chat = {
                  id: id_user,
                  userId: id_user,
                  unseenMsgs: 0,
                  chat: []
                }
                for (let i = 0; i < arr_chat.length; i++) {
                  const obj_chat = {
                    message: arr_chat[i].message,
                    time: arr_chat[i].created_at,
                    senderId: arr_chat[i].from
                  }
                  if (arr_chat[i].message_seen === 'no' && arr_chat[i].from !== getUserData().id) {
                    dem += 1
                  }
                  temp_chat.chat.push(obj_chat)
                }
                temp_chat.unseenMsgs = dem
                // console.log(temp_chat)
                list_chats.push(temp_chat)
                sessionStorage.setItem("chat_data", JSON.stringify(list_chats))
                if (list_contact.length === list_chats.length) {
                  dispatch(reloadDataChats())
                }
              }
            })
            .catch(function (error) {
              console.log(error)
            })
          // }
        })
      }
    })
    .catch(function (error) {
      console.log(error)
    })
  // }

  const userData = getUserData()
  const profileUserData = {
    id: userData.id,
    avatar: userData.avatar,
    fullName: userData.fullName,
    status: 'online'
  }

  const datachatsContacts = list_chats.map(chat => {
    const contact = list_contact.find(c => c.id === chat.userId)
    contact.chat = { id: chat.id, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
    return contact
  })
  // console.log(list_chats)
  // console.log(list_contact)
  return { chatsContacts: datachatsContacts, contacts: list_contact, profileUser: profileUserData }

  // const response = await axios.get('/apps/chat/chats-and-contacts')
  // return response.data
})

export const selectChat = createAsyncThunk('appChat/selectChat', async (id, { dispatch }) => {
  const userId = Number(id)
  sessionStorage.setItem("active_chat", userId)
  const contacts = JSON.parse(sessionStorage.getItem("contact_data"))
  const chats = JSON.parse(sessionStorage.getItem("chat_data"))
  // console.log(chats)
  const chat = chats.find(c => c.userId === userId)
  const contact = contacts.find(c => c.id === userId)
  if (contact.chat) contact.chat.unseenMsgs = 0

  const index_up = chats.findIndex(c => c.userId === userId)
  if (index_up >= 0) {
    if (chats[index_up].unseenMsgs > 0) {
      chats[index_up].unseenMsgs = 0
      sessionStorage.setItem("chat_data", JSON.stringify(chats))

      const FormData = require('form-data')
      const datas = new FormData()
      datas.append('userID', userId)

      const config = {
        method: 'post',
        url: `${Constants.REST_API_WORKSUITE}change-status-message`,
        headers: getHearderAuth(),
        data: datas
      }

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  // const response = await axios.get('/apps/chat/get-chat', { id })
  // await dispatch(getChatContacts())
  dispatch(reloadDataChats())
  return { chat, contact }
})

export const updateMsgChat = createAsyncThunk('appChat/updateMsgChat', async (obj, { dispatch }) => {
  // const userData = getUserData()
  const chats = JSON.parse(sessionStorage.getItem("chat_data"))

  let activeChat = chats.find(chat => chat.userId === obj.sendUserId)

  const newMessageData = {
    message: obj.message,
    time: new Date(),
    senderId: obj.sendUserId
  }
  // If there's new chat for user create one
  // console.log(obj)
  if (obj.contactId === getUserData().id) {
    const contacts = JSON.parse(sessionStorage.getItem("contact_data"))
    const newContact = contacts.find(contact => contact.id === obj.sendUserId)
    // console.log(newContact)
    if (newContact === undefined) {
      contacts.push(obj.sendInfor)
      sessionStorage.setItem("contact_data", JSON.stringify(contacts))
    }
    if (activeChat === undefined) {
      chats.push({
        id: obj.sendUserId,
        userId: obj.sendUserId,
        unseenMsgs: 1,
        chat: [newMessageData]
      })
      activeChat = chats[chats.length - 1]
    } else {
      activeChat.chat.push(newMessageData)
    }
  }

  try {
    const index_up = chats.findIndex(c => c.id === activeChat.id)
    chats[index_up] = activeChat
    chats[index_up].unseenMsgs += 1
    sessionStorage.setItem("chat_data", JSON.stringify(chats))
  } catch (error) {
    console.log(error)
  }

  const sendId = sessionStorage.getItem("active_chat")
  // console.log(Object.prototype.toString.call(sendId).slice(8, -1).toLowerCase())
  if (sendId) {
    if (obj.contactId === parseInt(sendId) || obj.sendUserId === parseInt(sendId)) {
      setTimeout(() => {
        dispatch(selectChat(sessionStorage.getItem("active_chat")))
      }, 500)
    }
  }

  return dispatch(reloadDataChats())
})

export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch }) => {
  // console.log(obj)
  const userData = getUserData()

  const FormData = require('form-data')
  const data_s = new FormData()
  data_s.append('message', obj.message)
  data_s.append('user_id', obj.contact.id)

  const config = {
    method: 'post',
    url: `${Constants.REST_API_WORKSUITE}send-message`,
    headers: getHearderAuth(),
    data: data_s
  }
  let obj_res = {}
  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data))
      // sessionStorage.clear()
    })
    .catch(function (error) {
      console.log(error)
    })

  const chats = JSON.parse(sessionStorage.getItem("chat_data"))

  let activeChat = chats.find(chat => chat.userId === obj.contact.id)
  const newMessageData = {
    message: obj.message,
    time: new Date(),
    senderId: userData.id
  }
  // If there's new chat for user create one
  let isNewChat = false
  if (activeChat === undefined) {
    isNewChat = true
    chats.push({
      id: obj.contact.id,
      userId: obj.contact.id,
      unseenMsgs: 0,
      chat: [newMessageData]
    })
    activeChat = chats[chats.length - 1]
  } else {
    activeChat.chat.push(newMessageData)
  }
  obj_res = { newMessageData, id: obj.contact.id }
  if (isNewChat) obj_res.chat = activeChat

  const index_up = chats.findIndex(c => c.id === activeChat.id)
  chats[index_up] = activeChat
  sessionStorage.setItem("chat_data", JSON.stringify(chats))

  // const response = await axios.post('/apps/chat/send-msg', { obj })
  // await dispatch(getChatContacts())
  await dispatch(selectChat(obj.contact.id))
  return { obj_res }
})

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chats: [],
    contacts: [],
    userProfile: {},
    selectedUser: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        // console.log(action)
        state.chats = action.payload.chatsContacts
        state.contacts = action.payload.contacts
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        // console.log(action)
        state.selectedUser = action.payload
      })
      .addCase(reloadDataChats.fulfilled, (state, action) => {
        // console.log(action)
        state.chats = action.payload.chatsContacts
        state.contacts = action.payload.contacts
      })
      .addCase(updateMsgChat.fulfilled, (state, action) => {
        // console.log(action)
        if (action.payload.payload) {
          state.chats = action.payload.payload.chatsContacts
          state.contacts = action.payload.payload.contacts
        }
      })
  }
})

export default appChatSlice.reducer
