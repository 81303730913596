import { getUserData } from "../src/auth/utils"

export const userId = getUserData()
export const GRAPHQL_API = "https://data2.cloodo.com/graphql"
export const AUTHOR_API = "https://erp.cloodo.com/api/v1/auth/login"
export const DEV_API = "https://club.cmsmart.net/check/api_worksuite.php"
export const REST_API = "https://data2.cloodo.com/api"
export const TOKEN_API = "D9ABCC03D2FC87DB89DAE2BA20DCAB55"
export const Client_ID = "721561722913-fs6fp8ef2g4te10qmce9u6pd17o9d3hm.apps.googleusercontent.com"
export const NAME_APP = "Chat"
export const REST_API_4 = "https://strapi4.cloodo.com/api"
export const LOGO_URL = "https://erp.cloodo.com/front/img/worksuite-logo.png"
export const REST_API_WORKSUITE = "https://erp.cloodo.com/api/v1/"
export const SEVER_CHAT = "https://socket.cloodo.com"