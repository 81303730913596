/* eslint-disable */
import jwtDefaultConfig from './jwtDefaultConfig'
import * as Constants from './../../../constants'
import { MD5 } from 'crypto-js'
import jwt from 'jsonwebtoken'
import axios from 'axios'
import { getHearderAuth } from '@utils'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          // config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              // originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getJWT() {
    return localStorage.getItem(this.jwtConfig.storageUserData)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  async login(...args) {
    const jwtConfig = {
      secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
      refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
      expireTime: '10m',
      refreshTokenExpireTime: '10m'
    }

    let error = {
      email: ['Something went wrong']
    }

    let user_sp = {}
    const qs = require('qs');
    const data2 = qs.stringify({
      'email': args[0].email,
      'password': args[0].password
    });

    const config = {
      method: 'post',
      url: Constants.DEV_API,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      data: data2
    }

    await axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data))
        user_sp = response.data
      })
      .catch(function (error) {
        console.log(error)
      })

    // console.log(user_sp)
    if (user_sp.error) {
      error = {
        email: [user_sp.error.message]
      }
    } else {
      if (user_sp.data.user) {
        try {
          const user_id = user_sp.data.user.id
          user_sp.id = user_id
          user_sp.fullName = user_sp.data.user.name
          user_sp.username = user_sp.data.user.name
          user_sp.password = ''
          user_sp.avatar = user_sp.data.user.image_url
          user_sp.email = user_sp.data.user.email
          user_sp.role = 'client'
          user_sp.ability = [
            {
              action: 'read',
              subject: 'ACL'
            },
            {
              action: 'read',
              subject: 'Auth'
            }
          ]
          user_sp.extras = {
            eCommerceCartItemsCount: 0
          }

          const accessToken = jwt.sign({ id: user_id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
          const refreshToken = jwt.sign({ id: user_id }, jwtConfig.refreshTokenSecret, {
            expiresIn: jwtConfig.refreshTokenExpireTime
          })

          const userData = { ...user_sp }

          delete userData.password

          const config2 = {
            method: 'get',
            url: `${Constants.REST_API_WORKSUITE}create-client-account`,
            headers: {
              Authorization: `Bearer ${user_sp.data.token}`
            }
          }
          await axios(config2)
            .then(function (response2) {
              console.log(response2.data)
            })
            .catch(function (error) {
              console.log(error)
            })

          const response = {
            userData,
            accessToken,
            refreshToken
          }
          // console.log(response)
          return [200, response]
        } catch (e) {
          error = e
        }
      } else {
        error = {
          email: ['Email or Password is Invalid']
        }
      }
    }

    // console.log(error)
    return [400, { error }]

    // return await axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }
}
